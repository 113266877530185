import React from "react"
import Layout from "../components/layout"

import { usePrismServiceCentersPage } from '../hooks/use_prims_service_centers'

import { ServiceCentersCover } from '../content/service_centers/_service_centers_cover';
import { ServiceCentersContact } from '../content/service_centers/_service_centers_contact';
import { ServiceCentersHighlights } from '../content/service_centers/_service_centers_highlights';
import { ServiceCentersStorytelling } from '../content/service_centers/_service_centers_storytelling';
import { ServiceCentersMap } from '../content/service_centers/_service_centers_map';
import { ServiceCentersReportAndInstall } from '../content/service_centers/_service_centers_report_and_install';
import { ServiceCentersWarranty } from '../content/service_centers/_service_centers_warranty';
import { ServiceCentersFooterText } from '../content/service_centers/_service_centers_footer_text';

import "../styles/pages/service_centers/service_centers.scss"

const ServiceCenters = () => {

  const serviceCenters = usePrismServiceCentersPage().edges
  const data = serviceCenters[0].node.data

  return (
    <Layout>
      {data.body.map((block) => {
        if (block.slice_type === 'cover') return <ServiceCentersCover key={block.id} {...block.primary} />
        if (block.slice_type === 'contact') return <ServiceCentersContact key={block.id} {...block.primary} />
        if (block.slice_type === 'highlights') return <ServiceCentersHighlights key={block.id} items={block.items} {...block.primary} />
        if (block.slice_type === 'storytelling') return <ServiceCentersStorytelling key={block.id} {...block.primary} />
        if (block.slice_type === 'map') return <ServiceCentersMap key={block.id} {...block.primary} />
        if (block.slice_type === 'report_and_installation') return <ServiceCentersReportAndInstall key={block.id} items={block.items} {...block.primary} />
        if (block.slice_type === 'activate_your_warranty') return <ServiceCentersWarranty key={block.id} {...block.primary} />
        if (block.slice_type === 'footer_text') return <ServiceCentersFooterText key={block.id} {...block.primary} />
        return null
      })}
    </Layout>
  )
}

export default ServiceCenters