import React from 'react';
import DetailLogo from '../../content/model_detail/detail_logo';

export const ServiceCentersCover = ({ description }) => {
  return (
    <div className="sc-block sc-cover sc-padding">
      <DetailLogo />
      <div className="sc-cover__description" dangerouslySetInnerHTML={{ __html: description.html }} />
    </div>
  )
};
