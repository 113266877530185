import React from 'react';

export const ServiceCentersStorytelling = ({ description }) => {
  return (
    <div className="sc-block sc-storytelling">
      <div className="sc-storytelling__container">
        <div className="sc-storytelling__text">SERVICIO</div>
        <div className="sc-storytelling__decription sc-padding" dangerouslySetInnerHTML={{ __html: description.html }} />
      </div>
    </div>
  )
};
