import React from 'react';

export const ServiceCentersMap = ({ description, link, label_button, logo, map }) => {
  return (
    <div className="sc-block sc-map sc-padding">
      <div className="sc-map__image-content">
        <img className="sc-map__image" src={map.url} alt='Mapa de México' />
      </div>
      <div className="sc-map__content">
        <img className="sc-map__logo" src={logo.url} alt='Servicio Calorex Logo' />
        <div className="sc-map__description" dangerouslySetInnerHTML={{ __html: description.html }} />
        <a href={link.url} target={link.target} rel={`${link.target === '_blank' ? 'noopener noreferrer' : ''}`} className="sc-map__link o-link-semicircle">
          <div className={`o-link-semicircle-white`}></div>
          <p className={`o-link-semicircle_type_item`}>{label_button.text}</p>
        </a>
      </div>
    </div>
  )
};
