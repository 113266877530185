import { useStaticQuery, graphql } from "gatsby"
export const usePrismServiceCentersPage = () => {
  const prismic = useStaticQuery(
    graphql`
      query{
        allPrismicServiceCenters {
          edges {
            node {
              data {
                body {
                  ... on PrismicServiceCentersBodyCover {
                    id
                    slice_type
                    primary {
                      description {
                        html
                      }
                    }
                  }
                  ... on PrismicServiceCentersBodyContact {
                    id
                    slice_type
                    primary {
                      title {
                        html
                      }
                      description {
                        html
                      }
                      label_button {
                        text
                      }
                      telephone
                      image {
                        url
                      }
                    }
                  }
                  ... on PrismicServiceCentersBodyHighlights {
                    id
                    slice_type
                    primary {
                      title {
                        html
                      }
                    }
                    items {
                      title {
                        html
                      }
                      description {
                        html
                       }
                    }
                  }
                  ... on PrismicServiceCentersBodyStorytelling {
                    id
                    slice_type
                    primary {
                      description {
                        html
                      }
                    }
                  }
                  ... on PrismicServiceCentersBodyMap {
                    id
                    slice_type
                    primary {
                      description {
                        html
                      }
                      link {
                        target
                        url
                      }
                      label_button {
                        text
                      }
                      logo {
                        url
                      }
                      map {
                        url
                      }
                    }
                  }
                  ... on PrismicServiceCentersBodyReportAndInstallation {
                    id
                    slice_type
                    primary {
                      title {
                        html
                      }
                    }
                    items {
                      description {
                        text
                        html
                      }
                      icon {
                        url
                      }
                    }
                  }
                  ... on PrismicServiceCentersBodyActivateYourWarranty {
                    id
                    slice_type
                    primary {
                      description {
                        html
                      }
                      label_button {
                        text
                      }
                      link {
                        target
                        url
                      }
                      image {
                        url
                      }
                    }
                  }
                  ... on PrismicServiceCentersBodyFooterText {
                    id
                    slice_type
                    primary {
                      text {
                        html
                      }
                    }
                  }
                }
              }
            }
          }
        }
      }
    `
  );
  return prismic.allPrismicServiceCenters
}