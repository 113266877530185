import React from 'react';
import DetailLogo from '../../content/model_detail/detail_logo';

export const ServiceCentersWarranty = ({ description, link, label_button, image }) => {
  return (
    <div className="sc-block sc-warranty sc-padding">
      <div className="sc-warranty__content">
        <DetailLogo />
        <div className="sc-warranty__description" dangerouslySetInnerHTML={{ __html: description.html }} />
      </div>
      <div className="sc-warranty__image-outer-content">
        <div className="sc-warranty__image-inner-content">
          <img className="sc-warranty__image" src={image.url} alt={label_button.text} />
          <a href={link.url} target={link.target} rel={`${link.target === '_blank' ? 'noopener noreferrer' : ''}`} className="sc-warranty__link o-link-semicircle">
            <div className={`o-link-semicircle-white`}></div>
            <p className={`o-link-semicircle_type_item`}>{label_button.text}</p>
          </a>
        </div>
      </div>
    </div>
  )
};
