import React from 'react';
import DetailLogo from '../../content/model_detail/detail_logo';

export const ServiceCentersHighlights = ({ title, items }) => {
  return (
    <div className="sc-block sc-highlights sc-padding">
      <div className="sc-highlights__content">
        <DetailLogo />
        <div className="sc-highlights__title" dangerouslySetInnerHTML={{ __html: title.html }} />
        <div className="sc-highlights__items">
          {items.map((highlight, index) => {
            return <div key={`highlight-${index}`} className="sc-highlight">
              <div className="sc-highlight__title" dangerouslySetInnerHTML={{ __html: highlight.title.html }} />
              <div className="sc-highlight__description" dangerouslySetInnerHTML={{ __html: highlight.description.html }} />
            </div>
          })}
        </div>
      </div>
    </div>
  )
};
