import React, { useEffect, useState } from 'react';
import Slider from "react-slick"
import { useCurrenDeviceMaintenance } from "../../hooks/useCurrentDeviceMaintenance"
import DetailLogo from '../../content/model_detail/detail_logo';

export const ServiceCentersReportAndInstall = ({ title, items }) => {
  const [existSlider, setExistSlider] = useState(false)
  const [device] = useCurrenDeviceMaintenance()
  const settings = {
    arrows: false,
    dots: true,
    dotsClass: "pagination__dots",
    customPaging: function (i) {
      return <span className="pagination__bullet"></span>
    },
    infinite: false,
    responsive: [
      {
        breakpoint: 1279,
        settings: {
          slidesToShow: 2,
          slidesToScroll: 2,
        }
      },
      {
        breakpoint: 767,
        settings: {
          slidesToShow: 1,
          slidesToScroll: 1
        }
      }
    ],
    slidesToShow: 4,
    slidesToScroll: 4,
    speed: 500,
  }

  useEffect(() => {
    if (device === 'mobile' || device === 'tablet' || device === 'desktopMin') {
      console.log('device-->', device)
      setExistSlider(true)
    } else {
      setExistSlider(false)
    }
  }, [device])

  return (
    <div className="sc-block sc-report-and-install sc-padding">
      <div className="sc-report-and-install__header">
        <DetailLogo />
        <div className="sc-report-and-install__title" dangerouslySetInnerHTML={{ __html: title.html }} />
      </div>
      <div className="sc-report-and-install__slider">
        {existSlider ?
          <Slider {...settings}>
            {items.map((item, index) => {
              return <div key={`report-and-install-${index}`}>
                <div className="sc-report-and-install__item-container">
                  <div className="sc-report-and-install__item-icon-container">
                    <img className="sc-report-and-install__item-icon" src={item.icon.url} alt={item.description.text} />
                  </div>
                  <div className="sc-report-and-install__item-description" dangerouslySetInnerHTML={{ __html: item.description.html }} />
                </div>
              </div>
            })}
          </Slider>
          :
          <>
            {items.map((item, index) => {
              return <div key={`report-and-install-${index}`}>
                <div key={`report-and-install-${index}`} className="sc-report-and-install__item-container">
                  <div className="sc-report-and-install__item-icon-container">
                    <img className="sc-report-and-install__item-icon" src={item.icon.url} alt={item.description.text} />
                  </div>
                  <div className="sc-report-and-install__item-description" dangerouslySetInnerHTML={{ __html: item.description.html }} />
                </div>
              </div>
            })}
          </>
        }
      </div>
    </div>
  )
};
