import React from 'react';
import DetailLogo from '../../content/model_detail/detail_logo';

import bg from "../../../static/assets/images/service_centers/cs_contact_bg.png";


export const ServiceCentersContact = ({ title, description, telephone, label_button, image }) => {
  return (
    <div className="sc-block sc-contact sc-padding">
      <div className="sc-contact__content">
        <DetailLogo />
        <div className="sc-contact__title" dangerouslySetInnerHTML={{ __html: title.html }} />
        <div className="sc-contact__description" dangerouslySetInnerHTML={{ __html: description.html }} />
        <a href={`tel:${telephone}`} className="sc-contact__link o-link-semicircle">
          <div className={`o-link-semicircle-white`}></div>
          <p className={`o-link-semicircle_type_item`}>{label_button.text}</p>
        </a>
      </div>
      <div className="sc-contact__image-outer-content">
        <div className="sc-contact__image-inner-content">
          <img src={bg} alt="home bg" className="sc-contact__image-bg" />
          <img src={image.url} alt={title} className="sc-contact__image" />
        </div>
      </div>
    </div>
  )
};
