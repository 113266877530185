import React from 'react';
import styles from '../../styles/_components/_model_detail/_logo_container.module.scss';
import CalorexLogo from '../../components/svg/commons/calorex_logo'
import CalorexLogoShadow from '../../components/svg/commons/calorex_logo_shadow'

function DetailLogo({ _name = '', _title = '', _shadow = false }) {
    return (
        <div className="detail_header_logo_container">
            <div className={`detail_header_info_logo ${styles.detail_header_info_logo}`}>
                {
                    _shadow ? <CalorexLogoShadow /> : <CalorexLogo />
                }
            </div>
            {
                _name !== null && _name !== '' ?
                    <div className={`detail_header_info_name ${styles.detail_header_info_name}`} dangerouslySetInnerHTML={{ __html: _name }} />
                : null
            }
            {_title!=='' ? <p className={`detail_header_info_title ${styles.detail_header_info_title}`}>{_title}</p> : null }
        </div>
    )
}
export default DetailLogo
